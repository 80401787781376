import { graphql, useStaticQuery } from "gatsby"

const useInsta = () => {
  const data = useStaticQuery(graphql`
    query InstaQuery {
      allInstaNode(limit: 12) {
        nodes {
          id
          caption
          username
          localFile {
            image: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return data.allInstaNode.nodes.map(node => ({
    ...node.localFile.image,
    caption: node.caption,
    username: node.username,
  }))
}

export default useInsta
