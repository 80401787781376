import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { DiscussionEmbed } from "disqus-react"

import useInsta from "../hooks/use-insta"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: `/page-2/`, title: `Page two` },
  }

  const instaNodes = useInsta()

  return (
    <Layout>
      <SEO title="Page two" />
      <h1>That's my instagram, guys!</h1>
      <p>Check it out!</p>
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`,
          // gridTemplateColumns: `repeat(3, minmax(200px, 1fr))`,
          // gridTemplateColumns: `repeat(3, minmax(200px, 1fr))`,
          // gridTemplateRows: `repeat(3, 1fr)`,
          gap: 0,
        }}
      >
        {instaNodes.map((node, index) => (
          <div key={index}>
            <Image
              fluid={{ ...node.fluid, aspectRatio: 1 }}
              alt={node.caption}
            />
          </div>
        ))}
      </div>

      {/* {JSON.stringify(instaNodes)} */}

      <DiscussionEmbed {...disqusConfig} />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default SecondPage
